import React from "react";
import Logo from "../assets/images/Logo_white.svg";
import Icon from "../assets/images/server-icon.png";
import { Twitch } from "@styled-icons/fa-brands";
import { Mail } from "react-feather";

export default function Footer() {
  return (
    <footer className="text-gray-100 body-font bg-topaz font-display shadow-inner">
      <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <a
          href="/"
          className="flex items-center md:justify-start justify-center"
        >
          <img
            fill="none"
            className="w-10 h-10 mx-2 rounded-full"
            src={Icon}
            alt="Logo"
          />
          <img src={Logo} alt="Logo" className="mt-1 w-32 mr-2"></img>
        </a>
        <div className="grid sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 mt-4 sm:mt-0 text-sm text-gray-10 text-center sm:text-left">
          <p>© 2020 OchreFox</p>
          <a
            href="https://iconscout.com/contributors/iconscout"
            target="_blank"
            rel="noopener noreferrer"
          >
            Images provided by Iconscout
          </a>
        </div>

        <span className="inline-flex sm:ml-auto sm:mt-0 justify-center sm:justify-start space-x-4 sm:mt-0 mt-4">
          <a href="https://www.twitch.tv/ochrefox">
            <Twitch size={24} />
          </a>
          <a href="mailto:contact@ochrefox.net">
            <Mail size={24} />
          </a>
        </span>
      </div>
    </footer>
  );
}
