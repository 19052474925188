import React, { useEffect } from "react";
import useSound from "use-sound";
// Images
import splashDarken from "../../assets/images/skin-splash-darken.png";
import ChampionSquare from "../../assets/images/ChampionSquare.svg";
// SFX
import hoverSfx from "../../assets/audio/sfx-uikit-grid-hover.ogg";
import editSfx from "../../assets/audio/sfx-uikit-edit-click.ogg";
import releaseSfx from "../../assets/audio/sfx-uikit-grid-release.ogg";
import clickSfx from "../../assets/audio/sfx-uikit-generic-click-small.ogg";
import bigClickSfx from "../../assets/audio/sfx-profile-summoner-big-click.ogg";
// Data
import championData from "../../assets/data/champion_data_unified.json";
import "./comps.css";

export const CompViewer = () => {
  // Variables and constants
  var blueTeam = {
    top: null,
    jungle: null,
    mid: null,
    adc: null,
    support: null,
  };
  var redTeam = {
    top: null,
    jungle: null,
    mid: null,
    adc: null,
    support: null,
  };
  var blueTeamPowerSpikes = {
    top: null,
    jungle: null,
    mid: null,
    adc: null,
    support: null,
  };
  var redTeamPowerSpikes = {
    top: null,
    jungle: null,
    mid: null,
    adc: null,
    support: null,
  };
  var blueComp = {
    "Hard-Engage": 0,
    "Wombo-Combo": 0,
    "Poke-Siege": 0,
    "Split-Push": 0,
    Skirmish: 0,
    Protect: 0,
  };
  var redComp = {
    "Hard-Engage": 0,
    "Wombo-Combo": 0,
    "Poke-Siege": 0,
    "Split-Push": 0,
    Skirmish: 0,
    Protect: 0,
  };
  const latest = "10.19.1";

  // Background image preloading
  var preloadImg = null;

  // Functions
  // Audio play hooks
  const [playGridHover] = useSound(hoverSfx, { volume: 0.5 });
  const [playGridClick] = useSound(clickSfx, { volume: 0.5 });

  const checkPlaceholders = () => {
    Object.keys(blueTeam).forEach((lane) => {
      var element = document.querySelector("#blue-" + lane + "-img");
      var elementText = document.querySelector("#blue-" + lane + "-champ");
      if (blueTeam[lane] === null) {
        restorePlaceholders(element, elementText);
      } else {
        element.classList.remove("p-2");
      }
    });
    Object.keys(redTeam).forEach((lane) => {
      var element = document.querySelector("#red-" + lane + "-img");
      var elementText = document.querySelector("#red-" + lane + "-champ");
      if (redTeam[lane] === null) {
        restorePlaceholders(element, elementText);
      } else {
        element.classList.remove("p-2");
      }
    });

    function restorePlaceholders(element, elementText) {
      var defaultsrc = element.getAttribute("defaultsrc");
      element.src = defaultsrc;
      // Restore original placeholder
      var placeholder = elementText.getAttribute("placeholder");
      elementText.textContent = placeholder;
      // Add padding
      element.classList.add("p-2");
    }
  };

  /* CHAMPION GRID POPULATE */
  const populateGridChampions = () => {
    // Get grid element
    var grid = document.querySelector("#grid-champions");
    // Loop data
    Object.entries(championData).map((value, index) => {
      const championKey = value[0];
      const champion = value[1];
      // create template div container
      var championContainer = document.createElement("div");
      championContainer.className +=
        "champion-container justify-center max-w-sm rounded overflow-hidden shadow-lg transition ease-in-out duration-100 bg-white bg-opacity-75 hover:bg-brand-light transform hover:scale-110 hover:col-span-2";
      // Champion DnD
      championContainer.draggable = true;
      championContainer.setAttribute("championKey", championKey);
      // championContainer.addEventListener("dragstart", (event) =>
      //   dragChampion(event)
      // );
      // championContainer.addEventListener("dragend", () => restoreGrid());
      // Audio
      championContainer.addEventListener("mouseEnter", () => {
        // let audio = new Audio("sfx-uikit-grid-hover.ogg");
        // audio.volume = 0.5;
        // audio.play();
        playGridHover();
        console.log("xd");
      });
      championContainer.addEventListener("mousedown", () => {
        // let audio = new Audio("sfx-uikit-generic-click-small.ogg");
        // audio.volume = 0.5;
        // audio.play();
        playGridClick();
      });

      // img container
      var championImg = document.createElement("img");
      championImg.src =
        "https://ddragon.leagueoflegends.com/cdn/" +
        latest +
        "/img/champion/" +
        champion.id +
        ".png";
      championImg.loading = "lazy";
      championImg.width = 80;
      championImg.height = 80;

      //   championImg.draggable = true;
      //   championImg.setAttribute("onDragStart", "dragChampion(event)");

      // Title
      var championTitle = document.createElement("h3");
      championTitle.className += "font-medium text-sm text-center mb-2";
      championTitle.innerText = champion.name;

      // Append elements to container
      championContainer.appendChild(championImg);
      championContainer.appendChild(championTitle);

      // Append new container to grid
      grid.appendChild(championContainer);
    });
  };

  // On load
  useEffect(() => {
    // Get document elements
    var animateArea = document.querySelector("#animate-area");
    // Check placeholders
    checkPlaceholders();
    populateGridChampions();
  }, [checkPlaceholders, populateGridChampions]);

  return (
    <div className="body flex flex-col min-h-screen">
      {/* <!-- CHAMPION IMAGE --> */}
      <div id="animate-area" className="center-bg-image"></div>
      <img
        className="splash-darken center-bg-image"
        src={splashDarken}
        alt="splash-darken"
      />
      {/* <!-- CONTENT --> */}
      <div className="mx-8 flex-grow" id="wrapper">
        <h1
          className="sm:text-4xl text-3xl text-center title-font m-4 p-2 text-yellow-500 border-b border-yellow-600 font-beaufort-bold"
          id="title"
        >
          League Comp Viewer
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-6 gap-4 font-beaufort-regular">
          {/* <!-- BLUE SIDE INFO --> */}
          <div className="col-auto">
            <div className="bg-teal-900 rounded shadow-lg">
              <div className="p-3 space-y-3 flex flex-col">
                <div className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden">
                  <div className="p-4 slide-in-left" id="blue-comp">
                    <p className="text-md font-bold leading-tight text-gray-800">
                      COMPOSITION
                    </p>
                    <div
                      className="grid grid-cols-4 items-center my-2"
                      id="blue-Hard-Engage"
                    >
                      <p className="text-sm leading-tight text-gray-600 mr-3 col-span-2">
                        Hard Engage
                      </p>
                      <div
                        className="bg-red-600 text-xs leading-none py-1 text-center text-gray-200 col-span-2"
                        style={{ width: "0%", height: "20px" }}
                      >
                        0%
                      </div>
                    </div>
                    <div
                      className="grid grid-cols-4 items-center my-2"
                      id="blue-Wombo-Combo"
                    >
                      <p className="text-sm leading-tight text-gray-600 mr-3 col-span-2">
                        Wombo Combo
                      </p>
                      <div
                        className="bg-orange-600 text-xs leading-none py-1 text-center text-gray-200 col-span-2"
                        style={{ width: "0%", height: "20px" }}
                      >
                        0%
                      </div>
                    </div>
                    <div
                      className="grid grid-cols-4 items-center my-2"
                      id="blue-Poke-Siege"
                    >
                      <p className="text-sm leading-tight text-gray-600 mr-3 col-span-2">
                        Poke-Siege
                      </p>
                      <div
                        className="bg-blue-400 text-xs leading-none py-1 text-center text-gray-200 col-span-2"
                        style={{ width: "0%", height: "20px" }}
                      >
                        0%
                      </div>
                    </div>
                    <div
                      className="grid grid-cols-4 items-center my-2"
                      id="blue-Split-Push"
                    >
                      <p className="text-sm leading-tight text-gray-600 mr-3 col-span-2">
                        Split Push
                      </p>
                      <div
                        className="bg-indigo-600 text-xs leading-none py-1 text-center text-gray-200 col-span-2"
                        style={{ width: "0%", height: "20px" }}
                      >
                        0%
                      </div>
                    </div>
                    <div
                      className="grid grid-cols-4 items-center my-2"
                      id="blue-Skirmish"
                    >
                      <p className="text-sm leading-tight text-gray-600 mr-3 col-span-2">
                        Skirmish
                      </p>
                      <div
                        className="bg-yellow-500 text-xs leading-none py-1 text-center text-gray-200 col-span-2"
                        style={{ width: "0%", height: "20px" }}
                      >
                        0%
                      </div>
                    </div>
                    <div
                      className="grid grid-cols-4 items-center my-2"
                      id="blue-Protect"
                    >
                      <p className="text-sm leading-tight text-gray-600 mr-3 col-span-2">
                        Protect
                      </p>
                      <div
                        className="bg-gray-400 text-xs leading-none py-1 text-center text-gray-200 col-span-2"
                        style={{ width: "0%", height: "20px" }}
                      >
                        0%
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden">
                  <div className="p-4 space-y-2 slide-in-left">
                    <p className="text-md font-bold leading-tight text-gray-800">
                      DAMAGE
                    </p>
                    <table width="100%">
                      <tbody>
                        <tr className="flex transition-width" height="20px">
                          <td
                            className="bg-red-700 rounded-l-lg"
                            id="blue-ad-bar"
                            style={{ flexBasis: "33%" }}
                          ></td>
                          <td
                            className="bg-blue-700"
                            id="blue-ap-bar"
                            style={{ flexBasis: "33%" }}
                          ></td>
                          <td
                            className="bg-teal-300 rounded-r-lg"
                            id="blue-td-bar"
                            style={{ flexBasis: "33%" }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="grid grid-cols-2 items-center my-2">
                      <p className="text-sm leading-tight text-gray-600 mr-3">
                        Physical
                      </p>
                      <div
                        className="text-sm leading-none py-1 text-center text-gray-800"
                        id="blue-ad"
                      >
                        0%
                      </div>
                    </div>
                    <div className="grid grid-cols-2 items-center my-2">
                      <p className="text-sm leading-tight text-gray-600 mr-3">
                        Magical
                      </p>
                      <div
                        className="text-sm leading-none py-1 text-center text-gray-800"
                        id="blue-ap"
                      >
                        0%
                      </div>
                    </div>
                    <div className="grid grid-cols-2 items-center my-2">
                      <p className="text-sm leading-tight text-gray-600 mr-3">
                        True Damage
                      </p>
                      <div
                        className="text-sm leading-none py-1 text-center text-gray-800"
                        id="blue-td"
                      >
                        0%
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden">
                  <div className="p-4 space-y-2 slide-in-left">
                    <p className="text-md font-bold leading-tight text-gray-800">
                      TEAM POWER SPIKES
                    </p>
                    <div className="relative pt-1">
                      <div
                        className="overflow-hidden h-5 flex"
                        id="blue-team-powerspikes"
                      >
                        <div
                          style={{ width: "33%" }}
                          className="early flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center rounded-l-lg bg-gray-200"
                        >
                          Early
                        </div>
                        <div
                          style={{ width: "33%" }}
                          className="mid flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center bg-gray-200"
                        >
                          Mid
                        </div>
                        <div
                          style={{ width: "33%" }}
                          className="late flex flex-col text-center whitespace-nowrap text-white justify-center rounded-r-lg bg-gray-200"
                        >
                          Late
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  id="blue-reset"
                  className="bg-white tracking-wide text-gray-800 font-bold rounded-lg hover:bg-blue-400 active:bg-blue-700 hover:text-white shadow-md py-2 px-4 inline-flex items-center justify-center outline-none focus:outline-none"
                  style={{ transition: "all 0.15s ease" }}
                  type="button"
                  onClick="resetTeam(event)"
                >
                  <svg
                    viewBox="0 0 16 16"
                    className="fill-current w-4 h-4 mr-2"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                  </svg>
                  <span>Reset</span>
                </button>
              </div>
            </div>
          </div>
          {/* <!-- BLUE SIDE --> */}
          <div className="col-auto">
            <div className="bg-blue-300 rounded shadow-lg bg-opacity-50">
              <div className="p-3 space-y-3 flex flex-col" id="blue-side">
                <audio
                  id="edit-click"
                  src="sfx-uikit-edit-click.ogg"
                  volume="0.5"
                ></audio>
                <input
                  type="text"
                  className="flex-row text-2xl font-bold text-center text-blue-800 bg-white bg-opacity-75 focus:bg-opacity-100 rounded mb-2 slide-in-left"
                  placeholder="Blue Team"
                  onClick="playEditAudio()"
                />
                <div className="space-y-6">
                  <div className="lane-container max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                    <div
                      className="sm:flex sm:items-center p-4"
                      onDrop="dropChampion(event)"
                      onDragOver="allowDrop(event)"
                      id="blue-top"
                    >
                      <img
                        className="block mx-auto sm:mx-0 sm:flex-shrink-0 h-16 p-2 bg-black border-2 border-yellow-600 object-scale-down slide-in-left"
                        src="top.png"
                        defaultsrc="top.png"
                        alt="top"
                        id="blue-top-img"
                      />
                      <div className="flex-1 mt-4 sm:mt-0 sm:ml-4 text-center sm:text-left slide-in-left">
                        <p className="text-sm leading-tight text-gray-600">
                          TOP
                        </p>
                        <p
                          className="text-xl font-bold leading-tight border-b border-gray-400 text-yellow-600"
                          id="blue-top-champ"
                          placeholder="SUMMONER 1"
                        >
                          SUMMONER 1
                        </p>
                        <small className="font-normal leading-normal mt-0 mb-4 text-gray-500">
                          Power spikes
                        </small>
                        <div className="relative pt-1">
                          <div
                            className="overflow-hidden h-2 mb-4 flex"
                            id="blue-top-powerspikes"
                          >
                            <div
                              style={{ width: "33%" }}
                              className="early flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center rounded-l-lg bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="mid flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="late flex flex-col text-center whitespace-nowrap text-white justify-center rounded-r-lg bg-gray-200"
                            ></div>
                          </div>
                        </div>
                        {/* <div x-data="{ openAttributes: false }">
                        <button
                          @click="openAttributes = true"
                          className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                        >
                          Attributes
                        </button>
                        <ul
                          x-show.transition="openAttributes"
                          @click.away="openAttributes = false"
                        >
                          Dropdown Body
                        </ul>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="lane-container max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                    <div
                      className="sm:flex sm:items-center p-4"
                      onDrop="dropChampion(event)"
                      onDragOver="allowDrop(event)"
                      id="blue-jungle"
                    >
                      <img
                        className="block mx-auto sm:mx-0 sm:flex-shrink-0 h-16 bg-black border-2 border-yellow-600 object-scale-down slide-in-left"
                        src="jungle.png"
                        defaultsrc="jungle.png"
                        alt="jungle"
                        id="blue-jungle-img"
                      />
                      <div className="flex-1 mt-4 sm:mt-0 sm:ml-4 text-center sm:text-left slide-in-left">
                        <p className="text-sm leading-tight text-gray-600">
                          JUNGLE
                        </p>
                        <p
                          className="text-xl font-bold leading-tight border-b border-gray-400 text-yellow-600"
                          id="blue-jungle-champ"
                          placeholder="SUMMONER 2"
                        >
                          SUMMONER 2
                        </p>
                        <small className="font-normal leading-normal mt-0 mb-4 text-gray-500">
                          Power spikes
                        </small>
                        <div className="relative pt-1">
                          <div
                            className="overflow-hidden h-2 mb-4 flex"
                            id="blue-jungle-powerspikes"
                          >
                            <div
                              style={{ width: "33%" }}
                              className="early flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center rounded-l-lg bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="mid flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="late flex flex-col text-center whitespace-nowrap text-white justify-center rounded-r-lg bg-gray-200"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lane-container max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                    <div
                      className="sm:flex sm:items-center p-4"
                      onDrop="dropChampion(event)"
                      onDragOver="allowDrop(event)"
                      id="blue-mid"
                    >
                      <img
                        className="block mx-auto sm:mx-0 sm:flex-shrink-0 h-16 bg-black border-2 border-yellow-600 object-scale-down slide-in-left"
                        src="mid.png"
                        defaultsrc="mid.png"
                        alt="mid"
                        id="blue-mid-img"
                      />
                      <div className="flex-1 mt-4 sm:mt-0 sm:ml-4 text-center sm:text-left slide-in-left">
                        <p className="text-sm leading-tight text-gray-600">
                          MID
                        </p>
                        <p
                          className="text-xl font-bold leading-tight border-b border-gray-400 text-yellow-600"
                          id="blue-mid-champ"
                          placeholder="SUMMONER 3"
                        >
                          SUMMONER 3
                        </p>
                        <small className="font-normal leading-normal mt-0 mb-4 text-gray-500">
                          Power spikes
                        </small>
                        <div className="relative pt-1">
                          <div
                            className="overflow-hidden h-2 mb-4 flex"
                            id="blue-mid-powerspikes"
                          >
                            <div
                              style={{ width: "33%" }}
                              className="early flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center rounded-l-lg bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="mid flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="late flex flex-col text-center whitespace-nowrap text-white justify-center rounded-r-lg bg-gray-200"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lane-container max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                    <div
                      className="sm:flex sm:items-center p-4"
                      onDrop="dropChampion(event)"
                      onDragOver="allowDrop(event)"
                      id="blue-adc"
                    >
                      <img
                        className="block mx-auto sm:mx-0 sm:flex-shrink-0 h-16 bg-black border-2 border-yellow-600 object-scale-down slide-in-left"
                        src="bot.png"
                        defaultsrc="bot.png"
                        alt="adc"
                        id="blue-adc-img"
                      />
                      <div className="flex-1 mt-4 sm:mt-0 sm:ml-4 text-center sm:text-left slide-in-left">
                        <p className="text-sm leading-tight text-gray-600">
                          ADC
                        </p>
                        <p
                          className="text-xl font-bold leading-tight border-b border-gray-400 text-yellow-600"
                          id="blue-adc-champ"
                          placeholder="SUMMONER 4"
                        >
                          SUMMONER 4
                        </p>
                        <small className="font-normal leading-normal mt-0 mb-4 text-gray-500">
                          Power spikes
                        </small>
                        <div className="relative pt-1">
                          <div
                            className="overflow-hidden h-2 mb-4 flex"
                            id="blue-adc-powerspikes"
                          >
                            <div
                              style={{ width: "33%" }}
                              className="early flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center rounded-l-lg bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="mid flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="late flex flex-col text-center whitespace-nowrap text-white justify-center rounded-r-lg bg-gray-200"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lane-container max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                    <div
                      className="sm:flex sm:items-center p-4"
                      onDrop="dropChampion(event)"
                      onDragOver="allowDrop(event)"
                      id="blue-support"
                    >
                      <img
                        className="block mx-auto sm:mx-0 sm:flex-shrink-0 h-16 bg-black border-2 border-yellow-600 object-scale-down slide-in-left"
                        src="support.png"
                        defaultsrc="support.png"
                        alt="support"
                        id="blue-support-img"
                      />
                      <div className="flex-1 mt-4 sm:mt-0 sm:ml-4 text-center sm:text-left slide-in-left">
                        <p className="text-sm leading-tight text-gray-600">
                          SUPPORT
                        </p>
                        <p
                          className="text-xl font-bold leading-tight border-b border-gray-400 text-yellow-600"
                          id="blue-support-champ"
                          placeholder="SUMMONER 5"
                        >
                          SUMMONER 5
                        </p>
                        <small className="font-normal leading-normal mt-0 mb-4 text-gray-500">
                          Power spikes
                        </small>
                        <div className="relative pt-1">
                          <div
                            className="overflow-hidden h-2 mb-4 flex"
                            id="blue-support-powerspikes"
                          >
                            <div
                              style={{ width: "33%" }}
                              className="early flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center rounded-l-lg bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="mid flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="late flex flex-col text-center whitespace-nowrap text-white justify-center rounded-r-lg bg-gray-200"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- GRID --> */}
          <div className="col-span-2">
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 16 16"
              className="glow-left opacity-0"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
              />
            </svg>
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 16 16"
              className="glow-right opacity-0"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
              />
            </svg>
            <p id="champion-name-showcase"></p>
            <div
              className="bg-gray-400 rounded shadow-lg overflow-auto bg-opacity-50 slide-in-bottom"
              style={{ height: "85vh" }}
              id="champion-grid-scroll"
            >
              <div className="p-3 space-y-3 slide-in-bottom">
                <p className="text-center italic sticky top-0 bg-teal-200 z-10">
                  Drag and drop
                </p>
                <div
                  className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-4"
                  id="grid-champions"
                >
                  <div
                    className="flex flex-wrap justify-center max-w-sm rounded border-2 border-red-600 overflow-hidden shadow-lg transition ease-in-out duration-150 bg-white hover:bg-brand-light transform hover:scale-105 hover:col-span-2"
                    draggable="true"
                    championkey="None"
                    onDragStart="dragReset(event)"
                  >
                    <img
                      src={ChampionSquare}
                      className="bg-red-400"
                      height="80px"
                      width="80px"
                    />
                    <h3 className="font-medium text-sm text-center mb-2">
                      None
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- RED SIDE --> */}
          <div className="col-auto">
            <div className="bg-red-300 rounded shadow-lg bg-opacity-50">
              <div className="p-3 space-y-3 flex flex-col" id="red-side">
                <input
                  type="text"
                  className="flex-row text-2xl font-bold text-center text-red-800 bg-red-200 rounded mb-2 slide-in-right"
                  placeholder="Red Team"
                  onClick="playEditAudio()"
                />
                <div className="space-y-6">
                  <div className="lane-container max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                    <div
                      className="sm:flex sm:items-center p-4 justify-between"
                      onDrop="dropChampion(event)"
                      onDragOver="allowDrop(event)"
                      id="red-top"
                    >
                      <div className="flex-1 mt-4 sm:mt-0 sm:mr-4 text-center sm:text-left slide-in-right">
                        <p className="text-xs leading-tight text-gray-600">
                          TOP
                        </p>
                        <p
                          className="text-xl font-bold leading-tight border-b border-gray-400 text-yellow-600"
                          id="red-top-champ"
                          placeholder="SUMMONER 1"
                        >
                          SUMMONER 1
                        </p>
                        <small className="font-normal leading-normal mt-0 mb-4 text-gray-500">
                          Power spikes
                        </small>
                        <div className="relative pt-1">
                          <div
                            className="overflow-hidden h-2 mb-4 flex"
                            id="red-top-powerspikes"
                          >
                            <div
                              style={{ width: "33%" }}
                              className="early flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center rounded-l-lg bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="mid flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="late flex flex-col text-center whitespace-nowrap text-white justify-center rounded-r-lg bg-gray-200"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="block mx-auto sm:mx-0 sm:flex-shrink-0 h-16 bg-black border-2 border-yellow-600 object-scale-down slide-in-right"
                        src="top.png"
                        defaultsrc="top.png"
                        alt="top"
                        id="red-top-img"
                      />
                    </div>
                  </div>
                  <div className="lane-container max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                    <div
                      className="sm:flex sm:items-center p-4 justify-between"
                      onDrop="dropChampion(event)"
                      onDragOver="allowDrop(event)"
                      id="red-jungle"
                    >
                      <div className="flex-1 mt-4 sm:mt-0 sm:mr-4 text-center sm:text-left slide-in-right">
                        <p className="text-xs leading-tight text-gray-600">
                          JUNGLE
                        </p>
                        <p
                          className="text-xl font-bold leading-tight border-b border-gray-400 text-yellow-600"
                          id="red-jungle-champ"
                          placeholder="SUMMONER 2"
                        >
                          SUMMONER 2
                        </p>
                        <small className="font-normal leading-normal mt-0 mb-4 text-gray-500">
                          Power spikes
                        </small>
                        <div className="relative pt-1">
                          <div
                            className="overflow-hidden h-2 mb-4 flex"
                            id="red-jungle-powerspikes"
                          >
                            <div
                              style={{ width: "33%" }}
                              className="early flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center rounded-l-lg bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="mid flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="late flex flex-col text-center whitespace-nowrap text-white justify-center rounded-r-lg bg-gray-200"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="block mx-auto sm:mx-0 sm:flex-shrink-0 h-16 bg-black border-2 border-yellow-600 object-scale-down slide-in-right"
                        src="jungle.png"
                        defaultsrc="jungle.png"
                        alt="jungle"
                        id="red-jungle-img"
                      />
                    </div>
                  </div>
                  <div className="lane-container max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                    <div
                      className="sm:flex sm:items-center p-4 justify-between"
                      onDrop="dropChampion(event)"
                      onDragOver="allowDrop(event)"
                      id="red-mid"
                    >
                      <div className="flex-1 mt-4 sm:mt-0 sm:mr-4 text-center sm:text-left slide-in-right">
                        <p className="text-xs leading-tight text-gray-600">
                          MID
                        </p>
                        <p
                          className="text-xl font-bold leading-tight border-b border-gray-400 text-yellow-600"
                          id="red-mid-champ"
                          placeholder="SUMMONER 3"
                        >
                          SUMMONER 3
                        </p>
                        <small className="font-normal leading-normal mt-0 mb-4 text-gray-500">
                          Power spikes
                        </small>
                        <div className="relative pt-1">
                          <div
                            className="overflow-hidden h-2 mb-4 flex"
                            id="red-mid-powerspikes"
                          >
                            <div
                              style={{ width: "33%" }}
                              className="early flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center rounded-l-lg bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="mid flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="late flex flex-col text-center whitespace-nowrap text-white justify-center rounded-r-lg bg-gray-200"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="block mx-auto sm:mx-0 sm:flex-shrink-0 h-16 bg-black border-2 border-yellow-600 object-scale-down slide-in-right"
                        src="mid.png"
                        defaultsrc="mid.png"
                        alt="mid"
                        id="red-mid-img"
                      />
                    </div>
                  </div>
                  <div className="lane-container max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                    <div
                      className="sm:flex sm:items-center p-4 justify-between"
                      onDrop="dropChampion(event)"
                      onDragOver="allowDrop(event)"
                      id="red-adc"
                    >
                      <div className="flex-1 mt-4 sm:mt-0 sm:mr-4 text-center sm:text-left slide-in-right">
                        <p className="text-xs leading-tight text-gray-600">
                          ADC
                        </p>
                        <p
                          className="text-xl font-bold leading-tight border-b border-gray-400 text-yellow-600"
                          id="red-adc-champ"
                          placeholder="SUMMONER 4"
                        >
                          SUMMONER 4
                        </p>
                        <small className="font-normal leading-normal mt-0 mb-4 text-gray-500">
                          Power spikes
                        </small>
                        <div className="relative pt-1">
                          <div
                            className="overflow-hidden h-2 mb-4 flex"
                            id="red-adc-powerspikes"
                          >
                            <div
                              style={{ width: "33%" }}
                              className="early flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center rounded-l-lg bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="mid flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="late flex flex-col text-center whitespace-nowrap text-white justify-center rounded-r-lg bg-gray-200"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="block mx-auto sm:mx-0 sm:flex-shrink-0 h-16 bg-black border-2 border-yellow-600 object-scale-down slide-in-right"
                        src="bot.png"
                        defaultsrc="bot.png"
                        alt="adc"
                        id="red-adc-img"
                      />
                    </div>
                  </div>
                  <div className="lane-container max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                    <div
                      className="sm:flex sm:items-center p-4 justify-between"
                      onDrop="dropChampion(event)"
                      onDragOver="allowDrop(event)"
                      id="red-support"
                    >
                      <div className="flex-1 mt-4 sm:mt-0 sm:mr-4 text-center sm:text-left slide-in-right">
                        <p className="text-xs leading-tight text-gray-600">
                          SUPPORT
                        </p>
                        <p
                          className="text-xl font-bold leading-tight border-b border-gray-400 text-yellow-600"
                          id="red-support-champ"
                          placeholder="SUMMONER 5"
                        >
                          SUMMONER 5
                        </p>
                        <small className="font-normal leading-normal mt-0 mb-4 text-gray-500">
                          Power spikes
                        </small>
                        <div className="relative pt-1">
                          <div
                            className="overflow-hidden h-2 mb-4 flex"
                            id="red-support-powerspikes"
                          >
                            <div
                              style={{ width: "33%" }}
                              className="early flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center rounded-l-lg bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="mid flex flex-col mr-1 text-center whitespace-nowrap text-white justify-center bg-gray-200"
                            ></div>
                            <div
                              style={{ width: "33%" }}
                              className="late flex flex-col text-center whitespace-nowrap text-white justify-center rounded-r-lg bg-gray-200"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="block mx-auto sm:mx-0 sm:flex-shrink-0 h-16 bg-black border-2 border-yellow-600 object-scale-down slide-in-right"
                        src="support.png"
                        defaultsrc="support.png"
                        alt="support"
                        id="red-support-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- RED SIDE INFO --> */}
          <div className="col-auto">
            <div className="bg-teal-900 rounded shadow-lg">
              <div className="p-3 space-y-3 flex flex-col">
                <div className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden">
                  <div className="p-4 slide-in-right" id="red-comp">
                    <p className="text-md font-bold leading-tight text-gray-800">
                      COMPOSITION
                    </p>
                    <div
                      className="grid grid-cols-4 items-center my-2"
                      id="red-Hard-Engage"
                    >
                      <p className="text-sm leading-tight text-gray-600 mr-3 col-span-2">
                        Hard Engage
                      </p>
                      <div
                        className="bg-red-600 text-xs leading-none py-1 text-center text-gray-200 col-span-2"
                        style={{ width: "0%", height: "20px" }}
                      >
                        0%
                      </div>
                    </div>
                    <div
                      className="grid grid-cols-4 items-center my-2"
                      id="red-Wombo-Combo"
                    >
                      <p className="text-sm leading-tight text-gray-600 mr-3 col-span-2">
                        Wombo Combo
                      </p>
                      <div
                        className="bg-orange-600 text-xs leading-none py-1 text-center text-gray-200 col-span-2"
                        style={{ width: "0%", height: "20px" }}
                      >
                        0%
                      </div>
                    </div>
                    <div
                      className="grid grid-cols-4 items-center my-2"
                      id="red-Poke-Siege"
                    >
                      <p className="text-sm leading-tight text-gray-600 mr-3 col-span-2">
                        Poke-Siege
                      </p>
                      <div
                        className="bg-blue-400 text-xs leading-none py-1 text-center text-gray-200 col-span-2"
                        style={{ width: "0%", height: "20px" }}
                      >
                        0%
                      </div>
                    </div>
                    <div
                      className="grid grid-cols-4 items-center my-2"
                      id="red-Split-Push"
                    >
                      <p className="text-sm leading-tight text-gray-600 mr-3 col-span-2">
                        Split Push
                      </p>
                      <div
                        className="bg-indigo-600 text-xs leading-none py-1 text-center text-gray-200 col-span-2"
                        style={{ width: "0%", height: "20px" }}
                      >
                        0%
                      </div>
                    </div>
                    <div
                      className="grid grid-cols-4 items-center my-2"
                      id="red-Skirmish"
                    >
                      <p className="text-sm leading-tight text-gray-600 mr-3 col-span-2">
                        Skirmish
                      </p>
                      <div
                        className="bg-yellow-500 text-xs leading-none py-1 text-center text-gray-200 col-span-2"
                        style={{ width: "0%", height: "20px" }}
                      >
                        0%
                      </div>
                    </div>
                    <div
                      className="grid grid-cols-4 items-center my-2"
                      id="red-Protect"
                    >
                      <p className="text-sm leading-tight text-gray-600 mr-3 col-span-2">
                        Protect
                      </p>
                      <div
                        className="bg-gray-400 text-xs leading-none py-1 text-center text-gray-200 col-span-2"
                        style={{ width: "0%", height: "20px" }}
                      >
                        0%
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden">
                  <div className="p-4 space-y-2 slide-in-right">
                    <p className="text-md font-bold leading-tight text-gray-800">
                      DAMAGE
                    </p>
                    <table width="100%">
                      <tbody>
                        <tr className="flex transition-width" height="20px">
                          <td
                            className="bg-red-700 rounded-l-lg"
                            style={{ flexBasis: "33%" }}
                            id="red-ad-bar"
                          ></td>
                          <td
                            className="bg-blue-700"
                            style={{ flexBasis: "33%" }}
                            id="red-ap-bar"
                          ></td>
                          <td
                            className="bg-teal-300 rounded-r-lg"
                            style={{ flexBasis: "33%" }}
                            id="red-td-bar"
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="grid grid-cols-2 items-center my-2">
                      <p className="text-sm leading-tight text-gray-600 mr-3">
                        Physical
                      </p>
                      <div
                        className="text-sm leading-none py-1 text-center text-gray-800"
                        id="red-ad"
                      >
                        0%
                      </div>
                    </div>
                    <div className="grid grid-cols-2 items-center my-2">
                      <p className="text-sm leading-tight text-gray-600 mr-3">
                        Magical
                      </p>
                      <div
                        className="text-sm leading-none py-1 text-center text-gray-800"
                        id="red-ap"
                      >
                        0%
                      </div>
                    </div>
                    <div className="grid grid-cols-2 items-center my-2">
                      <p className="text-sm leading-tight text-gray-600 mr-3">
                        True Damage
                      </p>
                      <div
                        className="text-sm leading-none py-1 text-center text-gray-800"
                        id="red-td"
                      >
                        0%
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  id="red-reset"
                  className="bg-white tracking-wide text-gray-800 font-bold rounded-lg hover:bg-red-400 active:bg-red-700 hover:text-white shadow-md py-2 px-4 inline-flex items-center justify-center outline-none focus:outline-none"
                  style={{ transition: "all 0.15s ease" }}
                  type="button"
                  onClick="resetTeam(event)"
                >
                  <svg
                    viewBox="0 0 16 16"
                    className="fill-current w-4 h-4 mr-2"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                  </svg>
                  <span>Reset</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompViewer;
