import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/Logo3.svg";
import Navbar, { NavbarHamburger, MobileNavbar } from "./Navbar";
import { Camera, DollarSign, GitHub } from "react-feather";
import { Cube } from "@styled-icons/ionicons-outline";
import { motion, useViewportScroll, useTransform } from "framer-motion";

const LeagueIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32pt"
    height="32pt"
    viewBox="0 0 32 32"
  >
    <g id="surface16195497">
      <path d="M 6.210938 4 L 9 8.53125 L 9 23.453125 L 6.027344 28 L 23.453125 28 L 28.015625 22 L 16 22 L 16 4 Z M 9.789062 6 L 14 6 L 14 24 L 23.984375 24 L 22.464844 26 L 9.726562 26 L 11 24.046875 L 11 7.964844 L 10.851562 7.726562 Z M 18 6.191406 L 18 8.234375 C 22.003906 9.144531 25 12.726562 25 17 C 25 18.050781 24.816406 19.0625 24.484375 20 L 26.582031 20 C 26.851562 19.046875 27 18.039062 27 17 C 27 11.617188 23.113281 7.136719 18 6.191406 Z M 7 10.695312 C 5.992188 12.128906 5.316406 13.808594 5.085938 15.625 C 5.027344 16.074219 5 16.535156 5 17 C 5 17.5625 5.042969 18.113281 5.125 18.652344 C 5.382812 20.363281 6.042969 21.945312 7 23.304688 Z M 7 10.695312 " />
    </g>
  </svg>
);

const Header = (props) => {
  const [mobileMenuOpen, setMobileMenu] = useState(false);
  const { scrollY } = useViewportScroll();
  const background = useTransform(
    scrollY,
    [0, 100],
    ["rgba(240, 250, 254, 1)", "rgba(240, 250, 254, 0.85)"]
  );
  const backdropFilter = useTransform(
    scrollY,
    [0, 100],
    ["blur(0px)", "blur(10px)"]
  );
  const links = [
    {
      title: "About",
      items: [
        {
          icon: <Camera />,
          title: "Coming Soon",
          description: "WIP",
          to: "/xd",
        },
        {
          icon: <DollarSign />,
          title: "Coming Soon",
          description: "WIP",
        },
      ],
    },
    {
      title: "Minecraft",
      to: "/",
      icon: <Cube />,
    },
    {
      title: "Comp Builder",
      to: "/compbuilder",
      icon: LeagueIcon,
      badge: <span className="ml-2 badge ~positive">ALPHA</span>,
    },
    {
      title: "GitHub",
      to: "https://github.com/hazi98",
      icon: <GitHub />,
    },
  ];

  const toggleMobileMenu = () => {
    setMobileMenu(!mobileMenuOpen);
  };

  return (
    <motion.div
      className="fixed inset-x-0 top-0 shadow-md font-display z-10"
      style={{
        background,
        backdropFilter,
      }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center border-b-2 border-gray-400 py-6 md:justify-start md:space-x-10">
          <div className="lg:w-0 lg:flex-1">
            <Link to="/" className="flex w-30">
              <img className="h-8 w-auto sm:h-10" src={Logo} alt="Logo" />
            </Link>
          </div>
          <NavbarHamburger toggle={toggleMobileMenu} />
          <Navbar links={links} />
        </div>
      </div>
      <MobileNavbar
        mobileMenuOpen={mobileMenuOpen}
        toggle={toggleMobileMenu}
        links={links}
      />
    </motion.div>
  );
};

export default Header;
