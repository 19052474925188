import React from "react";
import UnderConstruction from "../components/UnderConstruction";
import Twitch from "../components/Twitch";

export default function Landing() {
  return (
    <>
      <Twitch />
      <UnderConstruction />
    </>
  );
}
