import React from "react";
import { TwitchPlayer } from "react-twitch-embed";

export default function Twitch() {
  return (
    <div className="container mx-auto flex py-20 flex-col items-center">
      <TwitchPlayer
        channel="ochrefox"
        parent={["ochrefox.net", "www.ochrefox.net"]}
        width="90%"
      />
    </div>
  );
}
