import React, { useState } from "react";
import { InternalLink } from "./InternalLink";
import Transition from "./Transition";
import DropdownCard, { MobileDropdown, MobileLinks } from "./DropdownCard";
import Logo from "../assets/images/Logo3.svg";

export default function Navbar(props) {
  const [activeDropdown, setActiveDropdown] = useState(-1);
  const toggleActiveDropdown = (index) => {
    if (activeDropdown === index) {
      setActiveDropdown(-1);
    } else {
      setActiveDropdown(index);
    }
  };
  const links = props.links || null;
  return (
    <nav className="hidden md:flex space-x-10">
      {links &&
        links.map((link, index) => {
          // Multi link dropdown
          if (link.items) {
            return (
              <div key={index} className="relative">
                <button
                  type="button"
                  onClick={() => toggleActiveDropdown(index)}
                  className={`${
                    activeDropdown === index ? "text-gray-900" : "text-gray-600"
                  } group inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150`}
                >
                  {link.icon &&
                    React.cloneElement(link.icon, {
                      key: index,
                      className: "inline-flex w-4 h-4 self-center mr-2",
                    })}
                  <span>{link.title}</span>
                  {link.badge}
                  <svg
                    className={`${
                      activeDropdown === index
                        ? "text-gray-600"
                        : "text-gray-400"
                    } h-5 w-5 group-hover:text-gray-600 group-focus:text-gray-600 transition ease-in-out duration-150`}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                <Transition
                  show={activeDropdown === index}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <DropdownCard items={link.items} />
                </Transition>
              </div>
            );
          }
          // Single link
          else {
            return (
              <InternalLink
                key={index}
                to={link.to || "/"}
                className="text-base leading-6 font-medium text-gray-600 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 flex"
              >
                {link.icon &&
                  React.cloneElement(link.icon, {
                    key: index,
                    className:
                      "inline-flex w-4 h-4 self-center mr-2 stroke-current fill-current",
                  })}
                {link.title}
                {link.badge}
              </InternalLink>
            );
          }
        })}
    </nav>
  );
}

export function NavbarHamburger({ toggle }) {
  return (
    <div className="-mr-2 -my-2 md:hidden">
      <button
        type="button"
        onClick={() => toggle()}
        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
      >
        <svg
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>
  );
}

export function MobileNavbar({ mobileMenuOpen, toggle, links }) {
  return (
    <Transition
      show={mobileMenuOpen}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 translate-x-full"
      enterTo="opacity-100 translate-x-0"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-full"
    >
      <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
        <div className="rounded-lg shadow-lg">
          <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 mb-4 px-5 space-y-6">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={Logo} alt="Workflow" />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    onClick={() => toggle()}
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <MobileDropdown links={links} />
            </div>
            <div className="py-6 px-3">
              <MobileLinks links={links} />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
