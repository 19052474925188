import React from "react";
import WIP from "../assets/images/page-under-construction.svg";

export default function UnderConstruction() {
  return (
    <section className="text-gray-700 body-font">
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <article className="prose mb-8">
            <h1>Baking something unique...</h1>
            <p>
              This page is still a work in progress. <br />
              Check back later to{" "}
              <small>
                <i>maybe</i>
              </small>{" "}
              see a surprise!
            </p>
          </article>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src={WIP}
          />
        </div>
      </div>
    </section>
  );
}
