import React from "react";
import { InternalLink } from "./InternalLink";

export default function DropdownCard(props) {
  const items = props.items || null;
  return (
    <div
      tabIndex="0"
      className="absolute -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
    >
      <div className="rounded-lg shadow-lg">
        <div className="rounded-lg shadow-xs overflow-hidden">
          <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
            {items &&
              items.map((item, index) => {
                return (
                  <InternalLink
                    key={index}
                    to={item.to || "/"}
                    className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-cyan-200 transition ease-in-out duration-150"
                  >
                    {React.cloneElement(item.icon, {
                      key: index,
                      className: "flex-shrink-0 h-6 w-6 text-indigo-600",
                    })}
                    <div className="space-y-1">
                      <p className="text-base leading-6 font-medium text-gray-900">
                        {item.title}
                      </p>
                      <p className="text-sm leading-5 text-gray-500">
                        {item.description}
                      </p>
                    </div>
                  </InternalLink>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export function MobileDropdown(props) {
  const links = props.links || null;
  return (
    <div>
      <nav className="grid row-gap-8">
        {links &&
          links.map((link, index) => {
            if (link.items) {
              return (
                <div key={index}>
                  <h2 className="text-lg leading-6 font-bold text-gray-900 border-b-2 mb-4">
                    {link.icon &&
                      React.cloneElement(link.icon, {
                        key: index,
                        className: "inline-flex w-4 h-4 self-center mr-2",
                      })}
                    {link.title}
                    {link.badge}
                  </h2>
                  {link.items.map((item, itemindex) => {
                    return (
                      <InternalLink
                        key={"a" + itemindex}
                        to={item.to || "/"}
                        className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                      >
                        {React.cloneElement(item.icon, {
                          key: itemindex,
                          className: "flex-shrink-0 h-6 w-6 text-indigo-600",
                        })}
                        <div className="text-base leading-6 font-medium text-gray-900">
                          {item.title}
                        </div>
                      </InternalLink>
                    );
                  })}
                </div>
              );
            } else {
              return null;
            }
          })}
      </nav>
    </div>
  );
}

export function MobileLinks(props) {
  const links = props.links || null;
  return (
    <div className="grid grid-cols-2">
      {links &&
        links.map((link, index) => {
          if (!link.items) {
            return (
              <div key={index} className="py-4 px-3 space-y-4">
                <div className="flex grid-cols-2 row-gap-4 col-gap-8">
                  <InternalLink
                    to={link.to || "/"}
                    className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150 "
                  >
                    {link.icon &&
                      React.cloneElement(link.icon, {
                        key: index,
                        className: "inline-flex w-4 h-4 self-center mr-2",
                      })}
                    {link.title}
                    {link.badge}
                  </InternalLink>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
}
