import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from "./templates/Layout";
import Landing from "./pages/Landing";
import CompBuilder from "./pages/compbuilder/CompBuilder";
import CompViewer from "./pages/compviewer/CompViewer";
import NotFound from "./components/NotFound";
import Champions from "./pages/compbuilder/Champions";
function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route exact path="/compbuilder">
            <CompBuilder />
          </Route>
          <Route exact path="/compbuilder/champions">
            <Champions />
          </Route>
          <Route exact path="/compbuilder/viewer">
            <CompViewer />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
