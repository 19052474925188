import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
export class Layout extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="pt-24 min-h-screen bg-gray-200">
          {this.props.children}
        </div>
        <Footer />
      </>
    );
  }
}

export default Layout;
