import React from "react";
import { Link } from "react-router-dom";
import LeagueIcon from "../../assets/images/league-of-legends.svg";
import { ReactComponent as ChampionSquare } from "../../assets/images/ChampionSquare.svg";
import { ReactComponent as Drafting } from "../../assets/images/mid.svg";
import { ReactComponent as Composition } from "../../assets/images/composition.svg";

export default function CompBuilder() {
  return (
    <section className="text-gray-700 body-font">
      <div className="container px-5 py-16 mx-auto">
        <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
          <img src={LeagueIcon} width={50} alt="league_icon" />
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
            Composition Builder
          </h1>
          <p className="lg:w-1/2 w-full leading-relaxed text-base">
            League of Legends
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 -m-4">
          <Link to="/compbuilder/champions">
            <div className="text-gray-100 p-4">
              <div className="flex border border-gray-300 p-6 rounded-lg bg-bluerasp">
                <ChampionSquare width={50} className="mr-4 fill-current" />
                <div className="flex-grow">
                  <h4 className="text-3xl mb-2 font-semibold">Champions</h4>
                  <p className="text-gray-400">Champion List</p>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/compbuilder/viewer">
            <div className="text-gray-100 p-4">
              <div className="flex border border-gray-300 p-6 rounded-lg bg-relay">
                <Composition width={50} className="mr-4 fill-current" />
                <div className="flex-grow">
                  <h4 className="text-3xl mb-2 font-semibold">Compositions</h4>
                  <p className="text-gray-400">Composition Viewer</p>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/compbuilder/draftsim">
            <div className="text-gray-100 p-4">
              <div className="flex border border-gray-300 p-6 rounded-lg bg-emerald">
                <Drafting width={50} className="mr-4 fill-current" />
                <div className="flex-grow">
                  <h4 className="text-3xl mb-2 font-semibold">Drafting</h4>
                  <p className="text-gray-400">Tournament Draft Simulator</p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}
