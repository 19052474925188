import React, { useState } from "react";
import LazyLoad from "react-lazy-load";
// import champion from "./champion_data_unified.json";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import champion from "../../assets/data/champion_data_unified.json";
import "./championStyles.css";
export default function Champions() {
  const latest = "10.19.1";
  const [selectedId, setSelectedId] = useState(null);
  return (
    <div className="container champion-background px-5 py-16 mx-auto text-center">
      <AnimateSharedLayout type="crossfade">
        <div className="grid grid-cols-3 md:grid-cols-5 lg:grid-cols-7 gap-4">
          {Object.entries(champion).map((value, index) => {
            const championKey = value[0];
            const champion = value[1];
            return (
              <motion.div
                layoutId={championKey}
                onClick={() => setSelectedId(championKey)}
                className="flex flex-wrap justify-center max-w-sm rounded overflow-hidden shadow-lg transition ease-in-out duration-150 bg-white hover:bg-brand-light transform hover:scale-105 hover:col-span-2"
                key={championKey}
              >
                <LazyLoad debounce={false}>
                  <img
                    alt={champion.id}
                    src={
                      "http://ddragon.leagueoflegends.com/cdn/" +
                      latest +
                      "/img/champion/" +
                      champion.id +
                      ".png"
                    }
                    className="p-4 w-auto"
                  />
                </LazyLoad>
                <h3 className="font-bold text-sm sm:text-lg lg:text-xl mb-2">
                  {champion.name}
                </h3>
              </motion.div>
            );
          })}
          <AnimatePresence>
            {selectedId && (
              <motion.div layoutId={selectedId}>
                <motion.button onClick={() => setSelectedId(null)} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </AnimateSharedLayout>
    </div>
  );
}
