import React from "react";
import { ErrorAlt } from "@styled-icons/boxicons-solid";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="min-h-screen bg-emerald">
      <section className="text-gray-100 body-font">
        <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <span className="rounded-full h-20 w-20 bg-brand p-4 shadow-md mb-8">
            <ErrorAlt />
          </span>
          <div className="text-center lg:w-2/3 w-full">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Oof...
            </h1>
            <p className="mb-8 leading-relaxed">
              The page you are trying to reach does not exist or is unavailable,
              yikes.
            </p>
            <div className="flex justify-center">
              <Link to="/">
                <button className="ml-4 inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-lg">
                  Return to Home
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
